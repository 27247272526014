.calendars-container {
  margin-top: 3rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.calendars {
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .fc-event,
  .fc-event-start,
  .fc-event-end,
  .fc-event-past,
  .fc-daygrid-event,
  .fc-daygrid-block-event,
  .fc-h-event {
    white-space: pre-wrap;
    font-size: 0.5px;
  }
}
