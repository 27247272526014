.Footer-container {
  position: relative;
  background-color: black;
}

.footer {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.siga-container {
  display: flex;
  color: white;
}

.footer > hr {
  border: 0.5px solid var(--lightgray);
  width: 100%;
}

.footer-l {
  flex: 2 1;
  display: grid;
  justify-content: center;
  align-items: center;
}

.footer-l p {
  font-size: 20px;
  font-weight: bold;
}

.footer-r {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  justify-content: center;
  padding: 20px;
}
.footer-r p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.jdo-container {
  display: flex;
  color: white;
  justify-content: space-between;
}

.jdo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px; /* Adjust the height as needed */
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.jdo-footer .home-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.jdo-footer .home-button:hover {
  background-color: #0056b3;
}

.jdo-footer .home-button a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
  }
  .footer-l {
    flex: 2 1;
    display: grid;
    justify-content: left;
    align-items: center;
    margin-right: 1%;
  }
  .footer-l p {
    font-size: 16px;
    font-weight: bold;
  }
}
