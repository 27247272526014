.about-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}

.about-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 3%;
}

.about-title-container {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.about-title-container > span {
  font-weight: bold;
  color: #d2d288;
}

.about-title-container > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.about-categories {
  display: flex;
  gap: 1rem;
}

.about-category {
  display: flex;
  flex-direction: column;
  background: rgba(15, 15, 17, 0.3);
  padding: 2rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}

.about-category > :nth-child(1) {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

.about-category > :nth-child(2) {
  width: auto;
  height: 2rem;
  font-size: 22px;
  font-weight: 700;
  color: #acb6e5;
  background: -webkit-linear-gradient(0deg, #acb6e5, #86fde8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-category > :nth-child(3) {
  font-size: 1rem;
  font-weight: bold;
  width: auto;
}

.about-category > :nth-child(4) {
  font-size: 0.9rem;
  line-height: 25px;
}

.about-more {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.about-more > img {
  width: 1rem;
}

.about-more:hover {
  opacity: 0.6;
}

.about-category:hover {
  background: rgba(34, 47, 62, 0.4);
  cursor: pointer;
  border: black dotted 0.8px;
}

.drone-garally-container {
  display: flex;
  gap: 2rem;
  margin-top: 5%;
}

.dji-link > a {
  color: white;
}
.dji-link > a:hover {
  color: var(--orange);
}

@media screen and (max-width: 768px) {
  .about-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .about-categories {
    flex-direction: column;
  }

  .drone-garally-container {
    flex-direction: column;
  }
}

/** modal css */

.modal-wrapper {
  position: fixed;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-outer-container {
  background-color: #1f242a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 90%;
  border: 5px solid #363d42;
  border-radius: 1%;
}

.modal-inner-container {
  background-color: transparent;
  width: 87%;
  height: 89%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #363d42;
  border-radius: 1%;
}

.about-title {
  width: auto;
  height: 2rem;
  font-size: 22px;
  font-weight: 700;
  color: #acb6e5;
  background: -webkit-linear-gradient(0deg, #acb6e5, #86fde8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.close_button {
  position: absolute;
  top: 60px;
  right: 32%;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

@media screen and (max-width: 768px) {
  .modal-outer-container {
    width: 80%;
    height: 40%;
  }

  .modal-inner-container {
    width: 80%;
    height: 82%;
  }

  .about-title {
    font-size: 16px;
  }

  .close_button {
    position: absolute;
    top: 32%;
    right: 15%;
    font-size: 24px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
}
