.contact {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 2rem;
}

.contact-left-h {
  padding-inline: 2rem;
  flex: 1;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.contact-figures > div {
  display: flex;
  flex-direction: column;
}

.contact-figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}

.contact-figures > div > span:nth-of-type(2) {
  color: var(--gray);
  font-size: 1rem;
  margin: 10px;
  margin-right: 0px;
}

.contact-right-h {
  flex: 1;
  margin-inline: 3rem;
  position: relative;
}

.contact-right-h > div > h1 {
  color: white;
  font-size: 2rem;
}

/** create the gradient bottom **/
h1:before {
  position: absolute;
  bottom: 15px;
  content: " ";
  text-align: center;
  display: block;
  height: 2px;
  width: 100%;
}
/* Here comes to good stuff : content styling */
#content {
  position: relative;
  width: 500px;
  min-height: 200px;
  z-index: 100;
  padding-inline: 30px;
}
/** my "fake" background that will hover the stripes **/
#content:after {
  background: transparent;
  margin: 10px;
  position: absolute;
  content: " ";
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  /*border-radius*/
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

/** */

.Form {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}
@media screen and (max-width: 480px) {
  .Form {
    margin-top: 40px;
  }
}
.Form-Item {
  border-top: 1px solid #ddd;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .Form-Item {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
    flex-wrap: wrap;
  }
}
.Form-Item:nth-child(5) {
  border-bottom: 1px solid #ddd;
}
.Form-Item-Label {
  width: 100%;
  max-width: 248px;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 1rem;
  color: white;
}
@media screen and (max-width: 480px) {
  .Form-Item-Label {
    max-width: inherit;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
}
.Form-Item-Label.isMsg {
  margin-top: 8px;
  margin-bottom: auto;
}
@media screen and (max-width: 480px) {
  .Form-Item-Label.isMsg {
    margin-top: 0;
  }
}
.Form-Item-Label-Required {
  border-radius: 6px;
  margin-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 40px;
  display: inline-block;
  text-align: center;
  background: #5bc8ac;
  color: #fff;
  font-size: 0.7rem;
}
@media screen and (max-width: 480px) {
  .Form-Item-Label-Required {
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 32px;
    font-size: 10px;
  }
}
.Form-Item-Input {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding-left: 1em;
  padding-right: 1em;
  height: 40px;
  width: 100%;
  max-width: 410px;
  background: #eaedf2;
  font-size: 0.8rem;
}
@media screen and (max-width: 480px) {
  .Form-Item-Input {
    margin-left: 0;
    margin-top: 18px;
    height: 40px;
    flex: inherit;
    font-size: 15px;
  }
}
.Form-Item-Textarea {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5rem;
  height: 216px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: #eaedf2;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .Form-Item-Textarea {
    margin-top: 18px;
    margin-left: 0;
    height: 200px;
    flex: inherit;
    font-size: 15px;
  }
}

/** button */

.btn-container {
  position: absolute;
  left: 50%;
  top: 108%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .btn-container {
    top: 105%;
  }
}

.btn-center {
  width: 180px;
  height: 60px;
  position: absolute;
}

.contact-btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #234567;
  outline: none;
  transition: 1s ease-in-out;
}

.contact-btn svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #91c9ff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.contact-btn:hover {
  transition: 1s ease-in-out;
  background: #4f95da;
}

.contact-btn:hover span {
  transition: 1s ease-in-out;
  color: #fff;
}

.contact-btn:hover svg {
  stroke-dashoffset: -480;
}

.contact-btn span {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

/*** form styling **/

@media screen and (max-width: 768px) {
  .contact {
    flex-direction: column;
  }

  .contact-figures > div {
    display: flex;
    flex-direction: column;
  }

  .contact-figures > div > span:nth-of-type(1) {
    color: white;
    font-size: 2rem;
    text-align: center;
  }

  .contact-figures > div > span:nth-of-type(2) {
    color: var(--gray);
    font-size: 1rem;
    text-align: center;
  }

  .contact-right-h {
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  /** create the gradient bottom **/
  h1:before {
  }
  /* Here comes to good stuff : content styling */
  #content {
    width: 85%;
    align-items: center;
  }

  /** we remove the red glow around required fields since we are already using the red star */
  input:required,
  textarea:required {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  /** inputs and textarea**/
  input:not([type="submit"]),
  textarea {
    width: 90%;
  }

  .select {
    width: 80%;
  }
}
