/* navbar.css */
.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: #acacac;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 100000;
}

.header {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: white;
  margin-bottom: 10px;
  background-color: #242d39;
  position: sticky;
  top: 0;
  z-index: 100000;
}

.brand-name {
  text-decoration: none;
  color: #acacac;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
  margin-right: 5%;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #acacac;
}

.navigation-menu li > :hover {
  color: var(--orange);
  cursor: pointer;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .navigation {
    background-color: #242d39;
  }
  .navigation-menu.expanded {
    z-index: 10000;
    background-color: transparent;
  }

  .hamburger {
    display: block;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: #242d39;
    border-top: 1px solid black;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: #acacac;
    width: 100%;
    padding: 1.5rem 0;
    font-size: 20px;
  }
  .navigation-menu li:hover {
    background-color: transparent;
  }
}
