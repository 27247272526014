.Join {
  display: flex;
  flex-direction: column;
  padding: 0 6rem;
  gap: 10rem;
}

.left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.left-j > hr {
  position: absolute;
  width: 100%;
  justify-content: center;
  border: 1px solid var(--lightgray);
  border-radius: 20%;
  margin: -10px 0;
}

.left-j > div {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 4rem;
}

.left-j > div > span {
  font-size: 16px;
}
.left-j > div > a {
  font-size: 16px;
  color: white;
  &:hover {
    color: aqua;
  }
}

@media screen and (max-width: 768px) {
  .Join {
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem;
  }

  .left-j {
    font-size: x-large;
    flex-direction: column;
  }

  .left-j > div {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .right-j {
    padding: 2rem;
  }
}
