.plans-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
}

.programs-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  margin-top: 3%;
  margin-bottom: 3%;
}

/** JDO 滋賀びわこ校 料金プランカード */
.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}

.plan:nth-child(2) {
  background: #99996a;
  transform: scale(1.1);
}

.plan > svg {
  fill: #d2d288;
  width: 2rem;
  height: 2rem;
}

.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.plan > :nth-child(3) {
  font-size: 1.5rem;
  font-weight: bold;
}

.plan > :nth-child(5) {
  font-size: 0.8rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.feature > img {
  width: 1rem;
}

.plans > :nth-child(2) > svg {
  fill: white;
}

.plan > :nth-child(2) > button {
  font-size: 1rem;
  font-weight: bold;
  color: #d2d288;
}

.plans-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}

.plans-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}

.btn,
a.btn,
button.btn {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  border-radius: 0.5rem;
  margin-top: 3%;
}

a.btn-svg {
  font-weight: 700;
  line-height: 54px;

  width: 204px;
  height: 54px;
  padding: 0;

  cursor: pointer;
  text-decoration: none;

  background-color: transparent;
}

a.btn-svg svg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

a.btn-svg svg rect {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;

  stroke: #fafafa;
  stroke-width: 2;
  stroke-dasharray: 200px, 16px;
  stroke-dashoffset: 70px;
}

a.btn-svg:hover svg rect {
  stroke-dashoffset: 284px;
}

a.btn-svg span {
  color: #fafafa;
}

/** JDO 本部 国家資格料金プランカード */

.national_plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.national_plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
  height: 23rem;
}

.national_plan:nth-child(1) {
  background: #99996a;
  transform: scale(1);
}
.national_plan:nth-child(3) {
  background: #99996a;
  transform: scale(1);
}

.national_plan > :nth-child(1) {
  font-weight: bold;
  color: red;
  font-size: 1.3rem;
  height: 15px;
}

.national_plan > :nth-child(2) {
  font-size: 0.9rem;
  font-weight: bold;
}

.national_plan:nth-child(1) > :nth-child(3) {
  font-size: 0.7rem;
  font-weight: bold;
}
.national_plan:nth-child(2) > :nth-child(3) {
  font-size: 0.7rem;
  font-weight: bold;
}
.national_plan > :nth-child(3) {
  font-size: 1rem;
  font-weight: bold;
}

.national_plan > :nth-child(4) {
  font-size: 0.7rem;
  font-weight: bold;
  height: 40px;
}

.national_plan > :nth-child(5) {
  font-size: 0.7rem;
}

/** JDO 本部 民間資格料金プランカード */
.private_plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.private_plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
  height: 22rem;
}

.private_plan > :nth-child(1) {
  font-weight: bold;
  font-size: 1.2rem;
}

.private_plan > :nth-child(2) {
  font-size: 1.1rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .plans {
    flex-direction: column;
  }

  .plans > :nth-child(2) {
    transform: none;
  }

  .national_plans {
    flex-direction: column;
  }
  .national_plan > :nth-child(4) {
    height: 0px;
  }
  .national_plan:nth-child(3) > :nth-child(4) {
    height: 25px;
  }
  .national_plan:nth-child(4) > :nth-child(4) {
    height: 25px;
  }

  .private_plans {
    flex-direction: column;
  }
  .private_plan {
    height: auto;
  }
}
